import React from 'react'
import DocumentTitle from 'react-document-title'

export default function Imprint() {
  return (
    <DocumentTitle title="Impressum | airlivery">
      <main className="max-width">
        <h1>Impressum</h1>
        <h2>Angaben gemäß §5 TMG</h2>
        <p>Jonas Kuske</p>
        <p>Sielstraße 5</p>
        <p>27568 Bremerhaven</p>
        <h2>Kontakt</h2>
        <p>Telefon: +491603336948</p>
        <p>
          Mail:
          <a href="mailto:mail@jonaskuske.com">mail@jonaskuske.com</a>
        </p>
        <h2>Verantwortlich für Inhalte gemäß 55 Abs. 2 RStV:</h2>
        <p>Jonas Kuske, Sielstraße 5 27568 Bremerhaven</p>
        <h2>Haftungsauschluss</h2>
        <h3>Haftung für Inhalte</h3>
        <p>
          Die Inhalte dieser Seite wurden mit größter Sorgfalt erstellt. Für die
          Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann ich
          jedoch keine Gewähr übernehmen. Als Diensteanbieter bin ich gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend
          entfernen.
        </p>
        <h3>Haftung für Links</h3>
        <p>
          Mein Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden
          Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
          Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
          verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werde ich derartige Links
          umgehend entfernen.
        </p>
        <h3>Urheberrecht</h3>
        <p>
          Die durch die Seitenbetreiber erstellten bzw. verwendeten Inhalte und
          Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
          Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
          außerhalb der Grenzen des Urheberrechtes bedürfen der Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitte ich um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde
          ich derartige Inhalte umgehend entfernen.
        </p>
      </main>
    </DocumentTitle>
  )
}
