const SET_ACTIVE_USER = 'user/setActiveUser'
const REMOVE_ACTIVE_USER = 'user/removeActiveUser'
const UPDATE_USER = 'user/updateUser'

const obj = {
  UPDATE_USER,
  SET_ACTIVE_USER,
  REMOVE_ACTIVE_USER,
}

export default obj
