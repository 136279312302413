const LOGIN = 'auth/LOGIN'
const LOGOUT = 'auth/LOGOUT'
const ADD_KNOWN_USER = 'auth/addKnownUser'
const REMOVE_KNOWN_USER = 'auth/removeKnownUser'

const obj = {
  LOGIN,
  LOGOUT,
  ADD_KNOWN_USER,
  REMOVE_KNOWN_USER,
}

export default obj
