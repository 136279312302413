const ADD_PAYMENT_METHOD = 'payments/methods/addPaymentMethod'
const REMOVE_PAYMENT_METHOD = 'payments/methods/removePaymentMethod'
const UPDATE_PAYMENT_METHOD = 'payments/methods/updatePaymentMethod'

const obj = {
  ADD_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
}

export default obj
