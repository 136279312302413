import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Drone(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.38,11.24h1.21a.14.14,0,0,0,.14-.14V9.9a.14.14,0,0,0-.14-.14H11.38a.14.14,0,0,0-.14.14v1.2A.14.14,0,0,0,11.38,11.24Z" />
      <path d="M23.74,6.77H21a.22.22,0,0,0-.22-.22.22.22,0,0,0-.23.22H17.74a.23.23,0,1,0,0,.45h2.77v.6a.91.91,0,0,0-.71.9v.44H16.62v0a1.07,1.07,0,0,0-1.07-1.07H8.42A1.07,1.07,0,0,0,7.35,9.14v0H4.2V8.72a.91.91,0,0,0-.71-.9v-.6H6.26a.23.23,0,1,0,0-.45H3.49a.22.22,0,0,0-.23-.22A.22.22,0,0,0,3,6.77H.26A.22.22,0,0,0,0,7a.22.22,0,0,0,.22.22H3v.6a.92.92,0,0,0-.72.9v1.33a.94.94,0,0,0,.94.93h0a.93.93,0,0,0,.93-.93V9.61H7.35v0a1.07,1.07,0,0,0,1.07,1.08h1v.38a2,2,0,0,0,.11.65,3.09,3.09,0,0,0-1.68,2.74V15a.21.21,0,0,0,.07.16.23.23,0,0,0,.16.06h.63v3.19a.24.24,0,0,0,.16.21l2.53.8h.12l3.5-.8a.22.22,0,0,0,.18-.22V15.24h.62a.21.21,0,0,0,.22-.22v-.53a3.09,3.09,0,0,0-1.67-2.74,2,2,0,0,0,.11-.65v-.38h1a1.08,1.08,0,0,0,1.08-1.08v0H19.8v.44a.93.93,0,0,0,.93.93h0a.94.94,0,0,0,.94-.93V8.73A.94.94,0,0,0,21,7.82v-.6h2.78A.22.22,0,0,0,24,7,.23.23,0,0,0,23.74,6.77ZM10.8,9.9a.58.58,0,0,1,.58-.59h1.21a.58.58,0,0,1,.58.59v1.2a.59.59,0,0,1-.58.59H11.38a.59.59,0,0,1-.58-.59V9.9Zm.48,9L9.2,18.26V14.8l.93.29,1.16.37v3.46Zm.24-3.86-1.69-.53,2.65-.61,1.69.54-1.39.32Zm3.27,3.19-3.06.7V15.47l1.42-.32,1.64-.38Zm.84-3.76h0v.3h-.39v-.3h0a.21.21,0,0,0-.16-.22l-2.52-.8a.25.25,0,0,0-.12,0l-3.51.81a.22.22,0,0,0-.18.2h0v.31H8.34v-.31a2.64,2.64,0,0,1,1.43-2.34,1.92,1.92,0,0,0,1.61.88h1.21a1.92,1.92,0,0,0,1.61-.88A2.64,2.64,0,0,1,15.63,14.49Z" />
    </SvgIcon>
  )
}
