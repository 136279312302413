import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Send() {
  return (
    <SvgIcon>
      <path d="M4.1,9.41a.08.08,0,0,0,0-.09L2.71,8.59a.08.08,0,0,0-.09,0,.06.06,0,0,0,0,.08L4,9.44H4s0,0,.06,0m.28.48a.07.07,0,0,0,0-.09L2.71,8.9a.07.07,0,0,0-.09,0,.05.05,0,0,0,0,.07l1.65.9h0a.07.07,0,0,0,0,0m-1,4.45a.16.16,0,0,0,.06.22h0l2.16,1.13.07,0a.16.16,0,0,0,.16-.16.16.16,0,0,0-.08-.14L3.55,14.27a.16.16,0,0,0-.22.05h0m0-.79a.16.16,0,0,0,.06.22h0L6.1,15.19l.07,0a.16.16,0,0,0,.16-.16.17.17,0,0,0-.09-.14L3.55,13.47a.16.16,0,0,0-.22,0h0m0-.79a.16.16,0,0,0,.06.22h0l4,2.09.08,0a.16.16,0,0,0,.15-.16.13.13,0,0,0-.08-.14l-4-2.1a.15.15,0,0,0-.22.06h0m0-.79a.16.16,0,0,0,.06.22h0l3.26,1.7.08,0a.16.16,0,0,0,.08-.3h0L3.54,11.88a.15.15,0,0,0-.21,0v0m-.7-2.37a.07.07,0,0,0,0,.09l.91.5h0a.08.08,0,0,0,.06,0,.06.06,0,0,0,0-.08l-.91-.5a.07.07,0,0,0-.09,0m0-.32s0,.06,0,.08h0L3.78,10h0a.07.07,0,0,0,.06,0,.05.05,0,0,0,0-.08h0L2.71,9.23a.08.08,0,0,0-.09,0m13.26-2L12.81,8.85,6,5.29,9.1,3.67ZM5.61,5.51l6.78,3.57L9.1,10.81,2.33,7.24Zm3.3,14.72L2.09,16.65V7.55l6.82,3.6ZM16.12,7.55v4.07H12.67v3.54h3.45v1.49L9.3,20.23V11.15L9.55,11Zm3.06,2.33v1.74H16.9V7.13a.39.39,0,0,0-.21-.35l-7.4-3.9a.4.4,0,0,0-.37,0l-7.4,3.9a.39.39,0,0,0-.21.35v9.74a.39.39,0,0,0,.21.35l7.4,3.9a.4.4,0,0,0,.37,0l7.41-3.9a.4.4,0,0,0,.2-.35V15.16h2.28V16.9l3.51-3.51Z" />
    </SvgIcon>
  )
}
